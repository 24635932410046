import React, {useRef} from 'react';
import { useTranslation, Trans } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Container from "react-bootstrap/Container";

import MainButton from "../../components/main-button/MainButton";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
//import SliderTestimonials from "../../components/slider-testimonials/SliderTestimonials";
import ContactUsComponent from '../../components/contact-us/ContactUsComponent';
import News from "../../components/news/News";

import btnAndroid from '../../resources/img/btn-android.svg';
import btnIos from '../../resources/img/btn-ios.svg';

import phones from '../../resources/img/phones.webp';
import img_landlords from './img/landlords.webp';
import phones_img_ro from "../../resources/img/phones_ro.png"

import './Home.scss';

import { AppModelService } from '../../AppModel';
import HomeDescription from '../../components/home-description/HomeDescription';


const HomeComponent = (props) => {

    const { t } = useTranslation();

    const hasTranslationValue = (v) => {
        let translation = t(v);
        if(!translation) {
            return false;
        }

        let r  = translation !== v;
        return r;
    }

    return (

        <div className="home-component">
            <HomeDescription></HomeDescription>

            <WhatIsMilluu />

            <div className='section-img'>

                {(AppModelService.getCountry() === 'ro') &&
                    <img src={phones_img_ro} alt=""></img>
                }

                {(AppModelService.getCountry() === 'pl') &&
                    <img src={phones} alt="" />
                }


            </div>

            <div className="section-cta">
                <Container className='container-page-aligned'>
                    <div className="row gy-3">
                        <div className="col-12 col-md">
                            <div className="box-wrapper">
                                <div className="box-header">
                                    <img src={img_landlords} alt="" />
                                </div>

                                <div className="description">
                                    <h2 className="section-title"><Trans i18nKey={`callToActionOwners.title`} /> </h2>
                                    <p className='part1'><Trans i18nKey={`callToActionOwners.description`} /> </p>
                                    {
                                        hasTranslationValue(`callToActionOwners.description_2`) &&
                                        <p className='part2'><Trans i18nKey={`callToActionOwners.description_2`} /> </p>
                                    }
                                </div>

                                <MainButton className="callToActionBtn" title={t(`callToActionOwners.button`)} to={AppModelService.getOwnersLink()} />
                            </div>
                        </div>

                        <div className="col-12 col-md">
                            <div className="box-wrapper">
                                <div className="box-header">
                                    <div className="buttons-group">
                                        <a className='me-3' href={AppModelService.getGooleAppLink()}><img src={btnAndroid} alt="" /></a>
                                        <a href={AppModelService.getIosAppLink()}><img src={btnIos} alt="" /></a>
                                    </div>
                                </div>

                                <div className="description flex-grow-1">
                                    <h2 className="section-title"><Trans i18nKey={`callToActionTenants.title`} /></h2>
                                    <p className='part1'><Trans i18nKey={`callToActionTenants.description`} /></p>
                                    {
                                        hasTranslationValue(`callToActionTenants.description_2`) &&
                                        <p className='part2'><Trans i18nKey={`callToActionTenants.description_2`} /></p>
                                    }

                                </div>

                                <MainButton className="callToActionBtn" title={t(`callToActionTenants.button`)} to={AppModelService.getTenantsLink()} />
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            {/* ./section-cta */}

            {/* {(AppModelService.getCountry() === 'ro') &&
                <SliderTestimonials testimonials={t(`reviewsHome.reviews`, { returnObjects: true })}>
                    <Trans i18nKey={`reviewsHome.title`} />
                </SliderTestimonials>
            } */}

            <News />

            <div className='float-container'>
                <ContactUsComponent />
            </div>
        </div>
    );
};

export default HomeComponent;