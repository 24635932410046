import React from 'react';
import { useTranslation, Trans } from "react-i18next";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";

import MainButton from "../../components/main-button/MainButton";

import illustrationQuestion from "./img/Illustration_question.png";
import iconInfo from '../../resources/img/icon-info-dynamic.svg';
import iconCheck from '../../resources/img/icon-check2.svg';
import img_services from './img/services_1500_2.png';

import './TenantsServices.scss';

const TenantsServices = (props) => {

    const { t } = useTranslation();

    const getActiveClass = (num) => {
        if (num == 2) {
            return props.tableActiveColumn === 'active-2' ? 'active' : '';
        }

        if (num == 3) {
            return props.tableActiveColumn === 'active-3' ? 'active' : '';
        }

        return '';
    }


    const getButtonPlanClass = (num) => {
        if (num == 2) {
            return props.tableActiveColumn === 'active-2' ? 'green' : 'dark';
        }

        if (num == 3) {
            return props.tableActiveColumn === 'active-3' ? 'green' : 'dark';
        }

        return '';
    }

    function changeActive(catIndex) {
        // props.handleSetTableActiveColumn(`active-${catIndex + 2}`)
    }

    function log() {
        console.log('tenants services log');
    }

    return (
        <div className='tenants-services-section'>
            <div className="section-costs" id="services">
                <Container className='container-page-aligned'>
                    <div className='row gy-5'>
                        <div className='col-lg-4 col-md-12 image-before'>
                            <img className='main-image' src={img_services} alt='' />
                        </div>

                        <div className='col-lg-8 col-md-12'>
                            <div className='services-header-mobile'>
                                <h2 className="section-title"><Trans i18nKey={`servicesTenants.title`} /></h2>
                                <div className='section-title-info'>
                                    <Trans i18nKey={`servicesTenants.titleInfo`} />
                                </div>

                                <div className='section-title-explore'>
                                    <Trans i18nKey={`servicesTenants.explore`} />
                                </div>
                            </div>

                            <div className={`table-services ${props.tableActiveColumn}`}>

                                <div className="row-head">
                                    <div className="col-row">
                                        <div className='services-header-desktop'>
                                            <h2 className="section-title"><Trans i18nKey={`servicesTenants.title`} /></h2>
                                            <div className='section-title-info'>
                                                <Trans i18nKey={`servicesTenants.titleInfo`} />
                                            </div>

                                            <div className='section-title-explore'>
                                                <Trans i18nKey={`servicesTenants.explore`} />
                                            </div>
                                        </div>
                                    </div>

                                    {(t(`servicesTenants.categories`, { returnObjects: true })).map((col, catIndex) => {
                                        return (
                                            <div key={catIndex} className={`col-row ${getActiveClass(catIndex + 2)}`} onClick={() => changeActive(catIndex)}>
                                                <div className={`cell-content plan-header ${getActiveClass(catIndex + 2)}`}>

                                                    <h2 className="plan-title">
                                                        <span dangerouslySetInnerHTML={{ __html: col.title }}></span>
                                                    </h2>
                                                    <div className='plan-fees'>
                                                        {col.fees.map((category, index) => {
                                                            return (
                                                                <div key={index} className='fee'>
                                                                    <Modal show={props.showModalFee[catIndex].fees[index].modalInfo.open} onHide={() => props.handleCloseModalFee(catIndex, index)}
                                                                        className="custom-modal" centered scrollable>
                                                                        <Modal.Header closeButton className="header-modal">
                                                                            <h2 className="section-title small-header">
                                                                                <strong dangerouslySetInnerHTML={{ __html: category.title }}>
                                                                                </strong>
                                                                                &nbsp;<span dangerouslySetInnerHTML={{ __html: category.description }}></span>
                                                                            </h2>
                                                                        </Modal.Header>
                                                                        <p dangerouslySetInnerHTML={{ __html: category.modalInfo.description }} />
                                                                    </Modal>
                                                                    <div className='fee-title'>
                                                                        <span>
                                                                            {category.title}
                                                                            <span className='text-nowrap w-auto'>&#65279;</span>
                                                                        </span>
                                                                        <button className="unstyled-button info-btn"
                                                                            onClick={() => props.handleShowModalFee(catIndex, index)}>
                                                                            <InfoIcon className="info-icon" />
                                                                        </button>
                                                                    </div>
                                                                    <div className='fee-description'>
                                                                        <span dangerouslySetInnerHTML={{ __html: category.description }} />
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>

                                {(t(`servicesTenants.options`, { returnObjects: true })).map((option, index) => {
                                    return (
                                        <div key={index} className="row-table">
                                            <Modal show={props.showModal[index].modalInfo.open} onHide={() => props.handleCloseModal(index)}
                                                className="custom-modal step-modal" centered scrollable>
                                                <Modal.Header closeButton className="header-modal">
                                                    <h2 className="section-title small-header"><strong>{option.title} </strong></h2>
                                                </Modal.Header>
                                                {/* <p><Trans i18nKey={option.modalInfo.description} /></p> */}
                                                <p dangerouslySetInnerHTML={{ __html: option.modalInfo.description }} />

                                            </Modal>

                                            <div className="col-row">
                                                <div className='cell-content plan-attribute-name'>
                                                    <p className="option-title">
                                                        {option.title}<span className='text-nowrap'>&#65279;<button className="unstyled-button info-btn"
                                                            onClick={() => props.handleShowModal(index)}>
                                                            {/* <img src={iconInfo} alt="" /> */}
                                                            <InfoIcon />
                                                        </button></span></p>
                                                </div>
                                            </div>
                                            <div className={`col-row ${getActiveClass(2)}`} onClick={() => props.handleSetTableActiveColumn('active-2')}>
                                                <div className={`plan-attribute-value cell-content ${getActiveClass(2)}`}>
                                                    {
                                                        option.plan1 &&
                                                        <img src={iconCheck} alt='' />
                                                    }
                                                    {
                                                        !option.plan1 &&
                                                        <CrossIcon />
                                                    }
                                                    {/* <img src={option.plan1 ? iconCheck : iconNo} alt="" /> */}
                                                </div>
                                            </div>
                                            {/* <div className={`col-row ${getActiveClass(3)}`} onClick={() => props.handleSetTableActiveColumn('active-3')}>
                                                <div className={`plan-attribute-value cell-content ${getActiveClass(3)}`}>
                                                    {
                                                        option.plan2 &&
                                                        <img src={iconCheck} alt='' />
                                                    }
                                                    {
                                                        !option.plan2 &&
                                                        <CrossIcon />
                                                    }
                                                </div>
                                            </div> */}
                                        </div>
                                    )
                                })}


                                <div className="row-footer">
                                    <div className="col-row" />
                                    {(t(`servicesTenants.categories`, { returnObjects: true })).map((col, catIndex) => {
                                        return (
                                            <div key={catIndex} className={`col-row ${getActiveClass(catIndex + 2)}`} onClick={() => props.handleSetTableActiveColumn(`active-${catIndex + 2}`)}>
                                                <div className={`plan-footer cell-content ${getActiveClass(catIndex + 2)}`}>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>

                        <div className='col-lg-4 col-md-12 image-after'>
                            <img className='main-image' src={img_services} alt='' />
                        </div>
                    </div>
                </Container>
            </div>

        </div >
    );
};

const CrossIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M1.5 1.31934L14 13.8194M14 13.8194L26.4999 26.3193M14 13.8194L26.5 1.31934M14 13.8194L1.50006 26.3193" stroke="currentColor" strokeWidth="2" stroke-linejoin="round" />
            </g>
        </svg>
    )
}

const InfoIcon2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path fill="none" d="M0,8a8,8,0,1,1,8,8A8,8,0,0,1,0,8Z" />
                    <path fill="currentColor" d="M8,5.16a.85.85,0,0,0,.88-.86A.82.82,0,0,0,8,3.51.83.83,0,1,0,8,5.16ZM7.31,12H8.69V6.13H7.31Z" />
                    <path fill="currentColor" d="M8,14.22A6.21,6.21,0,0,1,1.78,8H0a8,8,0,0,0,8,8ZM14.22,8A6.21,6.21,0,0,1,8,14.22V16a8,8,0,0,0,8-8ZM8,1.78A6.21,6.21,0,0,1,14.22,8H16A8,8,0,0,0,8,0ZM8,0A8,8,0,0,0,0,8H1.78A6.21,6.21,0,0,1,8,1.78Z" />
                </g>
            </g>
        </svg>
    )
}

const InfoIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" heigh="16" viewBox="0 0 16 16" className={`info-icon-component ${props.className}`} >
            <use href={`${iconInfo}#icon`} />
        </svg>
    )
}

export default TenantsServices;