import React from 'react';

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import './Features.scss';

const Features = (props) => {
    return (
        <div className="section-features">
            <Container className='g-0'>
                <div className="text-center text-md-start">
                    {props.children}
                </div>
                <Row className="justify-content-center">
                    {props.features.map((feature, index) => {
                        return (
                            <Col xl={4} md={6} key={index} className="col-feature">
                                <div className="box-wrapper box-border">
                                    <img src={feature.image} alt=""/>
                                    <h3 className={`section-title`}><strong>{feature.title}</strong></h3>
                                    <p dangerouslySetInnerHTML={{__html: feature.description}}/>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    );
};

export default Features;