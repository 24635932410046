import React from 'react';
import { useTranslation, Trans } from "react-i18next";

import TextLoop from "react-text-loop"

import Container from "react-bootstrap/Container";
import bgHero from './img/bg-home_xl.webp';

import './HomeDescription.scss';
import MainButton from '../main-button/MainButton';
import Cookies from 'js-cookie';
import axios from 'axios';

import { AppModelService } from '../../AppModel';

const HomeDescriptionContent = (props) => {

    const { t } = useTranslation();

    function log() {

        let link = t(`bannerOwners.button.link`);
        let finalLink = AppModelService.currentSettings().onboardingOwnerBaseUrl + link;

        if (Cookies.get('cookiebar') === 'CookieAllowed') {

            // const urlSearchParams = new URLSearchParams(window.location.search);
            // const params = Object.fromEntries(urlSearchParams.entries());
            let utmString = localStorage.getItem('utm');
            let utm = null;
            if (utmString) {
                try {
                    utm = JSON.parse(utmString);
                }
                catch (error) {
                    console.log('Error on parsing utm string ' + utmString + ': ' + error);
                }
            }

            console.log('Send lead utm: ' + utm);

            const ga = (window.ga && window.ga.getAll().length > 0) ? window.ga.getAll()[0].get('clientId') : 'adblock';
            const hj = (window.hj && window.hj.globals) ? window.hj.globals.key : 'adblock';

            // data to be sent to the POST request
            let _data = null;

            if (utm) {
                _data = {
                    'url': window.location.href,
                    'source': utm.source,
                    'medium': utm.medium,
                    'campaign': utm.campaign,
                    'content': utm.content,
                    'term': utm.term,
                    'finalurl': window.location.href,
                    'gid': ga,
                    'hjid': hj
                };
            }
            else {
                _data = {
                    'url': window.location.href,
                    'source': null,
                    'medium': null,
                    'campaign': null,
                    'content': null,
                    'term': null,
                    'finalurl': window.location.href,
                    'gid': ga,
                    'hjid': hj
                };
            }

            if (localStorage.getItem('ld')) {
                _data.ld = localStorage.getItem('ld');
            }
            const apiEndPoint = AppModelService.currentSettings().backendUri;
            axios.post(`${apiEndPoint}/add-lead.json`, _data)
                .then((response) => {
                    localStorage.setItem('ld', response.data);
                    window.location.href = finalLink;
                }, (error) => {
                    console.log(error);
                    //let xclock = t(`bannerOwners.button.link`);
                    window.location.href = finalLink;
                });

        } else {
            window.location.href = finalLink;
        }

    }

    return (
        <Container className='container-page-aligned'>
            <div className='home-description-content'>
                <div className='description-header'>
                    <Trans i18nKey={`home.title1`}/>
                    <br/>
                    <TextLoop interval={2000} springConfig={{stiffness: 100, damping: 20}}>
                        <a href={t(`home.title_link1`)}> <div dangerouslySetInnerHTML={{__html: t(`home.title_part1`)}} /> </a>
                        <a href={t(`home.title_link2`)}> <div dangerouslySetInnerHTML={{__html: t(`home.title_part2`)}} /> </a>
                    </TextLoop>
                    <br/>
                    <Trans i18nKey={`home.title2`}/>
                </div>
                <div className='description-info'>
                    <Trans i18nKey={`home.subtitle`}/>
                </div>
                <MainButton color="white-button milluu-w-100 milluu-w-sm-auto" onClick={log} title={t(`home.actionButton`)}></MainButton>
            </div>
        </Container>

    );
}

const HomeDescription = (props) => {
    return (
        <div className={`home-description ${props.className ? props.className : ''}`}>
            <div className='page-description'>
                <img className="bg-image" src={bgHero} alt=""/>
            </div>
            <div className='home-description-content-container '>
                <HomeDescriptionContent></HomeDescriptionContent>
            </div>
        </div>
    );
};

export default HomeDescription;