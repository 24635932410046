import React from 'react';

import Container from "react-bootstrap/Container";
import Features from "../features/Features";

import './FeaturesSection.scss';

const FeaturesSection = (props) => {
    return (
        <div className='wrapper-section-features'>
            <Container className='container-page-aligned'>
                <div className='section-header'>
                    <p className="section-title features-sub1">
                        <strong>
                            {props.title}
                        </strong>
                    </p>
                    <div className="features-sub2">
                        {props.subtitle}
                    </div>
                </div>

                <div className='row g-0 wrapper-section-features-features'>
                    <div className='col'>
                        <Features features={props.features}>
                        </Features>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default FeaturesSection;