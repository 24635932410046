import React from 'react';

import ContactComponent from "./ContactComponent";

const ContactContainer = () => {
    return (
        <ContactComponent />
    );
};

export default ContactContainer;