import React, {useState} from 'react';

import Steps2Component from "./Steps2Component";

const Steps2Container = (props) => {
    const [showModal, setShowModal] = useState(props.steps);

    const handleShowModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = true;
        setShowModal(showModalCopy);
    }

    const handleCloseModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = false;
        setShowModal(showModalCopy);
    }

    return (
        <Steps2Component
            {...props}
            showModal={showModal}
            handleShowModal={handleShowModal}
            handleCloseModal={handleCloseModal}
        />
    );
};

export default Steps2Container;