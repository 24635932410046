import ro from './layout/header/img/ro.svg';
import en from './layout/header/img/en.png';
import pl from './layout/header/img/pl.svg';

const countries = {
    ro: {nativeName: 'Romania', shortName: 'RO', icon: ro, code: 'ro' },
    pl: {nativeName: 'Poland', shortName: 'PL', icon: pl, code: 'pl'}
};


const lngs = {
    ro: {nativeName: 'Romana', shortName: 'RO', icon: ro, country: 'ro', countryDefault: true},
    en_ro: {nativeName: 'English', shortName: 'EN',  icon: en, country: 'ro', preserve: 'en' },
    en_pl: {nativeName: 'English', shortName: 'EN', icon: en, country: 'pl', preserve: 'en' },
    pl: {nativeName: 'Polski', shortName: 'PL', icon: pl, country: 'pl', countryDefault: true}
};

const pathTranslations = {
    pl: {
        '/właścicieli' : '/proprietari',
        '/wlascicieli' : '/proprietari',
        '/wlasciciele': '/proprietari',
        '/najemców': '/chiriasi',
        '/najemcy': '/chiriasi'
    },
    ro: {
        '/proprietari': '/wlasciciele',
        '/chiriasi' : '/najemcy'
    }
}

class AppModelServiceClass {

    constructor() {
        this.settings = {
            ro: {
                onboardingOwnerBaseUrl: process?.env?.REACT_APP_ONB_OWNER_BASE_URI_RO,
                backendUri: process?.env?.REACT_APP_BACKEND_URI_RO,
                uri: process?.env?.REACT_APP_URI_RO
            },
            pl: {
                onboardingOwnerBaseUrl: process?.env?.REACT_APP_ONB_OWNER_BASE_URI_PL,
                backendUri: process?.env?.REACT_APP_BACKEND_URI_PL,
                uri: process?.env?.REACT_APP_URI_PL
            }
        };
        this.context = null;
        this.stateArray = null;
    }

    currentSettings() {
        return this.settings[this.getCountry()];
    }

    init(countryState) {
        this.stateArray = countryState;
    }

    changeCountry(x, i18n) {

        x = x.toLocaleLowerCase();

        if (this.getCountry() === x) {
            return;
        }

        let location = window.location;

        let destination = this.settings[x];
        if (!destination) {
            console.error("invalid country: " + x);
            return;
        }

        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has('lng')) {
            queryParams.delete('lng')
        }

        /*if (i18n.language.startsWith('en_')) {
            queryParams.append('lng', 'en');
        }*/

        if (queryParams.has('autodetect')) {
            queryParams.delete('autodetect')
        }

        if (x === 'ro') {
            queryParams.append('autodetect', false);
        }

        let path = location.pathname.toLocaleLowerCase().startsWith('/parteneri') ? '/' : location.pathname;

        if (path.endsWith("/") && path.length > 1) {
            path = path.substring(0, path.length-1);
        }

        path = decodeURI(path);

        let translatedPath = pathTranslations[this.getCountry()][path];
        if (translatedPath) {
            path = translatedPath;
        }

        let queryParamsString = queryParams.toString();

        let url = destination.uri + path +  (queryParamsString.length > 0 ? '?' + queryParamsString : '');

        //console.log(url);

        location.assign(url);

        return;

        this.stateArray[1](x);

        const currentLng = i18n.language;
        if (!lngs[currentLng].country !== x) {
            let newLng = '';
            if (lngs[currentLng].preserve) {
                newLng = Object.keys(lngs).find(i => lngs[i].country === x && lngs[i].preserve === lngs[currentLng].preserve);
            }
            else  {
                newLng = Object.keys(lngs).find(i => lngs[i].country === x && lngs[i].countryDefault);
            }
            if(newLng) {
                console.log("country - change language: " + newLng);
                i18n.changeLanguage(newLng);
            }
        }
    }


    getCountry() {
        if (this.stateArray) {
            return this.stateArray[0];
        }
        return null;
    }


    getGooleAppLink() {
       return  'https://play.google.com/store/apps/details?id=com.stardevelopment.milluu&hl=' + this.getCountry();
    }

    getIosAppLink() {
        return  'https://apps.apple.com/app/id1466056170?l=' + this.getCountry();
    }

    getOwnersLink() {
        return  this.getCountry() === 'pl' ? '/wlasciciele' : '/proprietari';
    }

    getTenantsLink() {
        return  this.getCountry() === 'pl' ? '/najemcy' : '/chriasi';
    }

  }



const AppModelService = new AppModelServiceClass();

export { AppModelService, countries, lngs } ;
