import React from 'react';
import { useTranslation, Trans } from "react-i18next";

import Container from "react-bootstrap/Container";
import phones_img from "../../resources/img/phones.webp"
import phones_img_ro from "../../resources/img/phones_ro.png"
import btnAndroid from '../../resources/img/btn-android.svg';
import btnIos from '../../resources/img/btn-ios.svg';
import { AppModelService } from "../../AppModel.js";

import './WhatIsMilluu.scss';

const WhatIsMilluu = () => {
    const { t } = useTranslation();

    return (
        <div className="what-is-milluu">
            <Container className='container-page-aligned'>
                <div className='row g-0 what-is-milluu-container'>
                    <div className='col'>
                        <div className='row gy-4'>

                            <div className='col-12 col-md-6 col-lg-4'>
                                <div className="section-title-wrapper">
                                    <h2 className="section-title"><Trans i18nKey="about.title" /></h2>
                                </div>
                                <p className="intro-text">{t(`about.description`)}</p>
                            </div>

                            <div className='col-12 col-md-6 col-lg-4'>
                                <ul className="unstyled-list">
                                    {(t(`about.features`, { returnObjects: true })).map((feature, index) => {
                                        return (
                                            <li key={index}><Trans i18nKey={`about.features.${index}`} /></li>
                                        )
                                    })}
                                </ul>
                            </div>

                            <div className='d-none d-lg-flex col-lg-4 img-container'>
                                <div className='img'>


                                {(AppModelService.getCountry() === 'ro') &&
                                    <img src={phones_img_ro} alt=""></img>
                                }

                                {(AppModelService.getCountry() === 'pl') &&
                                    <img src={phones_img} alt=""></img>
                                }

                                </div>
                            </div>

                            <div className='col-12'>
                                    <div className="buttons-group">
                                        <a href={AppModelService.getGooleAppLink()}><img src={btnAndroid} alt="" /></a>
                                        <a href={AppModelService.getIosAppLink()}><img src={btnIos} alt="" /></a>
                                    </div>
                            </div>

                        </div>


                    </div>
                </div>

            </Container>
        </div>
    );
};

export default WhatIsMilluu;