import React from 'react';

import HeaderContainer from "./header/HeaderContainer";
import FooterContainer from "./footer/FooterContainer";

const Layout = (props) => {
    return (
        <div className="layout-component">
			<div className="header-wrapper">
				<HeaderContainer />
			</div>

			<div className="layout-wrapper">
				{React.cloneElement(props.children, { ...props })}
			</div>

			<div className="footer-wrapper">
				<FooterContainer />
			</div>
        </div>
    );
};

export default Layout;