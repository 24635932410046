import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import TagManager from 'react-gtm-module'
import { useState } from 'react';
import {useTranslation} from "react-i18next";

import Layout from "./layout/Layout";
import Routes from "./routes";

import 'bootstrap/dist/css/bootstrap.min.css';

import './App.scss';
import { AppModelService } from './AppModel';
import axios from 'axios';
import Cookies from 'js-cookie';


/*const tagManagerArgs = {
    gtmId: 'GTM-T8DSVRL'
}

TagManager.initialize(tagManagerArgs);*/

const startCountry = (process?.env?.REACT_APP_COUNTRY ?? '').toLowerCase();

let ipLookUpDone = false;
function ipLookUp(i18n) {
    if (ipLookUpDone) {
        return;
    }
    ipLookUpDone = true;

    axios.get('https://ipapi.co/json')
        .then((response) => {
            let countryCode = response.data.country_code.toLowerCase();
            let myCountryCode = countryCode === 'pl' ? 'pl' : 'ro';

            if (countryCode !== AppModelService.getCountry()) {
                AppModelService.changeCountry(myCountryCode);
            }

            //i18n.changeLanguage(AppModelService.stateArray[0]);

        }, (error) => {
            console.log('Request failed with error: ' + error);
        });
}

function App() {
    const {i18n} = useTranslation();
    let country =  startCountry ?  startCountry : (window.location.hostname.indexOf(".pl") >= 0 ? 'pl' : 'ro');
    let isInvitation = window.location.href.indexOf("/invitation") >= 0;

    const firstTime = !AppModelService.getCountry();

    const state = useState(country);
    AppModelService.init(state);

    const qp = new URLSearchParams(window.location.search);
    let utm = {
        'source': qp.get('utm_source'),
        'medium': qp.get('utm_medium'),
        'campaign': qp.get('utm_campaign'),
        'content': qp.get('utm_content'),
        'term': qp.get('utm_term')
    };

    if(utm.source) {
        let savedUtm = localStorage.getItem('utm');
        if (savedUtm) {
            let shouldDelete = savedUtm.source !== utm.source
                    || savedUtm.medium !== utm.medium
                    || savedUtm.campaign !== utm.campaign
                    || savedUtm.content !== utm.content
                    || savedUtm.term !== utm.term;
            if (shouldDelete) {
                if (localStorage.getItem('ld')) {
                    localStorage.removeItem('ld');
                    console.log('Received different utm than existing: removing old lead id');
                }
            }
        }

        let utmJson =  JSON.stringify(utm);

        localStorage.setItem('utm', utmJson);
        console.log('Received and set utm: ' + utmJson);
    }
    else {
        // google refferal
        let gad_source = qp.get('gad_source');
        let gclid = qp.get('gclid');

        if (gad_source && gclid) {
            utm = {
                'source': 'Google',
                'medium': gad_source,
                'campaign': gclid,
                'content': null,
                'term': null
            };
            if (localStorage.getItem('ld')) {
                localStorage.removeItem('ld');
                console.log('Received different utm than existing: removing old lead id');
            }

            let utmJson =  JSON.stringify(utm);

            localStorage.setItem('utm', utmJson);
            console.log('Received and set utm: ' + utmJson);
        }
    }

    if (firstTime) {
        if (country === 'ro' && !isInvitation) {
            const queryParams = new URLSearchParams(window.location.search);
            if (!queryParams.has('autodetect'))
                ipLookUp(i18n);
        }
    }

    return (
        <Router>
            <Layout>
                <div className="App">
                    <Routes />
                </div>
            </Layout>
        </Router>
    );



}

export default App;
