import React, { useRef } from 'react';
import { useTranslation, Trans } from "react-i18next";

import Slider from "react-slick";

import Container from "react-bootstrap/Container";

import img_arrowRight from './img/arrow-right.svg';
import img_arrowLeft from './img/arrow-left.svg';


import './News.scss';

const News = (props) => {
    const { t } = useTranslation();

    let sliderRef = useRef(null);
    const settingsSliderNews = {
        dots: false,
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    };
    const next = () => {
        sliderRef.slickNext();
    };
    const previous = () => {
        sliderRef.slickPrev();
    };

    return (
        <div className={`section-news ${props.className ?? ''}`}>
            <Container className='container-page-aligned'>

                <div className='section-title'>
                    <Trans i18nKey={`news_title`} />
                </div>

                <div className='sliderContainer'>
                    <a onClick={previous} className="slider-prev-button slider-buttons">
                        <img src={img_arrowLeft}></img>
                    </a>

                    <div>
                        <Slider ref={slider => { sliderRef = slider;}} {...settingsSliderNews} className="slider-news">
                            {t(`news`, { returnObjects: true }).map((item, index) => {
                                return (
                                    <div key={index}>
                                        <div className='d-flex flex-column col-news'>
                                            <p>{item.description}</p>
                                            <a href={item.link} target="_blank" rel="noreferrer">
                                                <img src={item.image} alt="" />
                                            </a>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>

                    <a onClick={next} className="slider-next-button slider-buttons">
                        <img src={img_arrowRight}></img>
                    </a>
                </div>
            </Container>
        </div>
    );
};

export default News;