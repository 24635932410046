import React, {useState} from 'react';
import {useTranslation} from "react-i18next";

import TenantsComponent from "./TenantsComponent";

const TenantsContainer = () => {
    const {t} = useTranslation();
    const optionsModals = t(`servicesTenants.options`, {returnObjects: true});
    const feesModals = t(`servicesOwners.categories`, {returnObjects: true});

    const [showModal, setShowModal] = useState(optionsModals);

    const [buttonStyle, setButtonStyle] = useState('white-button');

    const handleShowModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = true;
        setShowModal(showModalCopy);
    }

    const handleCloseModal = (index) => {
        let showModalCopy = [...showModal];
        showModalCopy[index].modalInfo.open = false;
        setShowModal(showModalCopy);
    }

    const [showModalFee, setShowModalFee] = useState(feesModals);

    const handleShowModalFee = (catIndex, index) => {
        let showModalCopy = [...showModalFee];
        showModalCopy[catIndex].fees[index].modalInfo.open = true;
        setShowModalFee(showModalCopy);
    }

    const handleCloseModalFee = (catIndex, index) => {
        let showModalCopy = [...showModalFee];
        showModalCopy[catIndex].fees[index].modalInfo.open = false;
        setShowModalFee(showModalCopy);
    }


    const [tableActiveColumn, setTableActiveColumn] = useState('active-3');

    const handleSetTableActiveColumn = (col) => {
        setTableActiveColumn(col);
    };

    return (
        <TenantsComponent
            showModal={showModal}
            handleShowModal={handleShowModal}
            handleCloseModal={handleCloseModal}
            showModalFee={showModalFee}
            handleShowModalFee={handleShowModalFee}
            handleCloseModalFee={handleCloseModalFee}
            tableActiveColumn={tableActiveColumn}
            handleSetTableActiveColumn={handleSetTableActiveColumn}
        />
    );
};

export default TenantsContainer;