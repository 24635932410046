import React from 'react';
import { useTranslation} from "react-i18next";
// import moment from 'moment';

import './ContactUs.scss';


const ContactUsComponent = (props) => {

    const { t } = useTranslation();

    
    function handleClick() {
        document.querySelector(".modalCt").style.display = "block";
    }
    
    function handleClose() {
        document.querySelector(".modalCt").style.display = "none";
    }

    const showContact = () => {
        return true;
        /*let currentTime = moment();
        let show = true;
      
        let extra = moment().format('YYYY-MM-DD') + ' ';
        let start_time = moment(extra + '09:00');
        let end_time = moment(extra + '18:00');
        if (!moment(currentTime).isBetween(start_time, end_time)) {
            show = false;
        }
        console.log("showContact - " + show);
        return show;*/
    }

    const showC = props.showContact ? props.showContact() : showContact();

   

    return (
        <div className='d-flex contact-us-component'>
            <div className='d-flex flex-column'>
                    <a className={"js-btn float " + (showC ? "showCt" : "hideCt")} onClick={handleClick} >
                        <i className="my-float">
                            <svg style={{ marginRight: 12.5 + 'px', marginLeft: -28 + 'px' }} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.03593 2.18841C1.03593 2.18841 1.69244 0.691602 2.67721 0.52529C3.66198 0.358978 4.48262 1.02423 5.46738 2.18841C6.45215 3.3526 5.95977 4.18416 5.46738 4.84941C4.975 5.51466 3.99023 6.34622 4.31849 7.01147C4.64674 7.67672 6.94454 10.1714 6.94454 10.1714C6.94454 10.1714 8.42169 11.8345 9.24233 11.8345C10.063 11.8345 10.3912 11.3356 10.8836 10.8367C11.376 10.3377 12.1966 9.17353 13.5097 10.1714C14.8227 11.1693 15.6433 11.8345 15.4792 12.6661C15.3151 13.4976 14.3303 15.3271 11.5401 15.4934C8.74994 15.6597 4.31849 12.6661 1.85657 8.67459C-0.605352 4.6831 1.03593 2.18841 1.03593 2.18841Z" fill="white" />
                            </svg>
                            {t(`callToActionCall.button`)}</i>
                    </a>

                    <a className={"js-btn float"} href='/contact' >
                        <i className="my-float">
                        <svg  style={{ marginRight: 12.5 + 'px', marginLeft: -28 + 'px' }} xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill="currentColor">
                            <path d="M180.309 843.999q-26.308 0-45.308-19t-19-45.308V372.309q0-26.308 19-45.308t45.308-19h599.382q27.308 0 45.808 19t18.5 45.308v407.382q0 26.308-18.5 45.308t-45.808 19H180.309ZM480 606.306l312-183.615L785.846 360 480 539 174.154 360 168 422.691l312 183.615Z"/>
                            </svg>
                        {t(`callToActionCall.buttonMessage`)}</i>
                    </a>
                </div>

                <div className="modalCt">
                    <div className="modal_content">
                        <span className="close" onClick={handleClose}>&times;</span>
                        <br></br>
                        <p style={{ width: 268 + 'px' }}>{t(`callToActionCall.text`)}</p>
                        <br></br>
                        <p><img src="https://res.cloudinary.com/milluu-com/image/upload/c_scale,h_32/v1630393039/Simbol_tk8m3y.png"></img>
                            &nbsp;<span className="agent">Sales Support</span>
                        </p>
                        <p className="callbutton">
                            <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.53593 6.18841C5.53593 6.18841 6.19244 4.6916 7.17721 4.52529C8.16198 4.35898 8.98262 5.02423 9.96738 6.18841C10.9522 7.3526 10.4598 8.18416 9.96738 8.84941C9.475 9.51466 8.49023 10.3462 8.81849 11.0115C9.14674 11.6767 11.4445 14.1714 11.4445 14.1714C11.4445 14.1714 12.9217 15.8345 13.7423 15.8345C14.563 15.8345 14.8912 15.3356 15.3836 14.8367C15.876 14.3377 16.6966 13.1735 18.0097 14.1714C19.3227 15.1693 20.1433 15.8345 19.9792 16.6661C19.8151 17.4976 18.8303 19.3271 16.0401 19.4934C13.2499 19.6597 8.81849 16.6661 6.35657 12.6746C3.89465 8.6831 5.53593 6.18841 5.53593 6.18841Z" stroke="#333333" />
                            </svg>
                            <span className="agent">
                                <a href={"tel:" + t(`callToActionCall.phone`)}>
                                    {t(`callToActionCall.phone`)}
                                </a>
                            </span>
                        </p>

                    </div>
                </div>
        </div>
    );
};

export default ContactUsComponent;