import React from 'react';
import { useTranslation, Trans } from "react-i18next";

import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Cookies from 'js-cookie';

import Features from "../../components/features/Features";
import Steps2Container from "../../components/steps2/Steps2Container";
//import SliderTestimonials from "../../components/slider-testimonials/SliderTestimonials";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import MainButton from "../../components/main-button/MainButton";
// import { Helmet } from 'react-helmet';


// import bgHeroMobile from '../home/img/bg-hero-proprietari-newmobile.png';

// import illustrationProprietar from "../../resources/img/illustration-proprietar.svg";
//import illustrationQuestion from "../owners/img/Illustration_question.png";
import iconInfo from '../../resources/img/icon-info-dynamic.svg';
import iconCheck from '../../resources/img/icon-check2.svg';
//import iconNo from '../../resources/img/icon-cross.svg';



import '../../resources/styles/secondary-lp.scss';
import './Owners.scss';
import axios from 'axios';
import { AppModelService } from "../../AppModel.js";
import ContactUsComponent from '../../components/contact-us/ContactUsComponent';

import img_landlords_room from './img/landlords-room.webp';
import img_evaluate from './img/evaluate.webp'
import img_hand_phone from './img/hand_phone.webp'
import img_hand_phone_sm from './img/hand_phone_sm.png'
import img_hand_phone_ro from './img/hand_phone_ro.png'
import img_hand_phone_sm_ro from './img/hand_phone_sm_ro.png'


import News from "../../components/news/News";

const OwnersComponent = (props) => {

    const { t } = useTranslation();

    const getActiveClass = (num) => {
        if (num == 2) {
            return props.tableActiveColumn === 'active-2' ? 'active' : '';
        }

        if (num == 3) {
            return props.tableActiveColumn === 'active-3' ? 'active' : '';
        }

        return '';
    }


    const getButtonPlanClass = (num) => {
        if (num == 2) {
            return props.tableActiveColumn === 'active-2' ? 'green' : 'dark';
        }

        if (num == 3) {
            return props.tableActiveColumn === 'active-3' ? 'green' : 'dark';
        }

        return '';
    }

    function log() {

        let link = t(`bannerOwners.button.link`);
        let finalLink = AppModelService.currentSettings().onboardingOwnerBaseUrl + link;

        if (Cookies.get('cookiebar') === 'CookieAllowed') {

            // const urlSearchParams = new URLSearchParams(window.location.search);
            // const params = Object.fromEntries(urlSearchParams.entries());
            let utmString = localStorage.getItem('utm');
            let utm = null;
            if (utmString) {
                try {
                    utm = JSON.parse(utmString);
                }
                catch (error) {
                    console.log('Error on parsing utm string ' + utmString + ': ' + error);
                }
            }

            console.log('Send lead utm: ' + utm);

            const ga = (window.ga && window.ga.getAll().length > 0) ? window.ga.getAll()[0].get('clientId') : 'adblock';
            const hj = (window.hj && window.hj.globals) ? window.hj.globals.key : 'adblock';

            // data to be sent to the POST request
            let _data = null;

            if (utm) {
                _data = {
                    'url': window.location.href,
                    'source': utm.source,
                    'medium': utm.medium,
                    'campaign': utm.campaign,
                    'content': utm.content,
                    'term': utm.term,
                    'finalurl': window.location.href,
                    'gid': ga,
                    'hjid': hj
                };
            }
            else {
                _data = {
                    'url': window.location.href,
                    'source': null,
                    'medium': null,
                    'campaign': null,
                    'content': null,
                    'term': null,
                    'finalurl': window.location.href,
                    'gid': ga,
                    'hjid': hj
                };
            }

            if (localStorage.getItem('ld')) {
                _data.ld = localStorage.getItem('ld');
            }
            const apiEndPoint = AppModelService.currentSettings().backendUri;
            axios.post(`${apiEndPoint}/add-lead.json`, _data)
                .then((response) => {
                    localStorage.setItem('ld', response.data);
                    window.location.href = finalLink;
                }, (error) => {
                    console.log(error);
                    //let xclock = t(`bannerOwners.button.link`);
                    window.location.href = finalLink;
                });

        } else {
            window.location.href = finalLink;
        }

    }

    return (
        <React.Fragment>
            {/* <Helmet>
            <title>
                {t('')}
            </title>
        </Helmet> */}

            <div className="owners-component secondary-lp">

                <div className='wrapper-section-hero-container page-description'>
                    <Container className="container-page-aligned wrapper-section-hero">
                        <div className='row g-0 w-100 h-100'>
                            <div className='col-12 col-md-6'>
                                <div className="wrapper-hero-description">
                                    <div>
                                        {/* <img src={illustrationProprietar} alt=""/> */}
                                        <h2 className="owner-h1"><Trans i18nKey={`bannerOwnersH1.title`} /></h2>
                                        <h2 className="owner-h2"><Trans i18nKey={`bannerOwnersH1.title2`} /></h2>
                                        <h2 className="owner-h3"><Trans i18nKey={`bannerOwnersH1.title3`} /></h2>
                                        <MainButton color="white-button milluu-w-100 milluu-w-md-auto" onClick={log} title={t(`bannerOwners.button.title`)} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 col-md-6'>

                                {(AppModelService.getCountry() === 'pl') &&
                                <div className='wrapper-hero-img'>
                                    <img className='not-mobile' src={img_hand_phone} alt=''/>
                                    <img className='mobile' src={img_hand_phone_sm} alt=''/>
                                </div>
                                }

                                {(AppModelService.getCountry() === 'ro') &&
                                <div className='wrapper-hero-img'>
                                    <img className='not-mobile' src={img_hand_phone_ro} alt=''/>
                                    <img className='mobile' src={img_hand_phone_sm_ro} alt=''/>
                                </div>
                                }

                            </div>
                        </div>
                    </Container>
                </div>

                <div className="wrapper-section-under-hero">
                    <Container className='container-page-aligned'>
                        <div className='section-header'>
                            <p className="section-title features-sub1"><strong>{t(`featuresOwners.subtitle`)} <br />
                                <span>{t(`featuresOwners.coloredText`)}</span></strong></p>
                            <div className="features-sub2">
                                {t(`featuresOwners.subtitle-2`)}
                                <br /><strong>{t(`featuresOwners.subtitle-2b`)}</strong>
                            </div>
                        </div>

                        <div className='section-features-pre-img'>
                            <img src={img_landlords_room} alt="" />
                        </div>

                        <div className='row gy-4 gx-5 section-features-pre'>
                            <div className="col-12 col-md d-flex">
                                <div className="feature-pre-wrapper feature-pre-wrapper-info">
                                    {
                                        (t(`featuresOwners.features-pre.0.title`) || t(`featuresOwners.features-pre.0.description`)) &&
                                        <div className='feature-pre-element mb-5'>
                                            <h3>{t(`featuresOwners.features-pre.0.title`)}</h3>
                                            <p>{t(`featuresOwners.features-pre.0.description`)}</p>
                                        </div>
                                    }

                                    <div className='feature-pre-element'>
                                        <h3>{t(`featuresOwners.features-pre.1.title`)}</h3>
                                        <p><Trans i18nKey={`featuresOwners.features-pre.1.description`} /></p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 col-md d-flex">
                                <div className="feature-pre-wrapper feature-pre-wrapper-list">
                                    <h3>{(t(`featuresOwners.features-pre.2.title`))}</h3>
                                    <div className="unstyled-list">
                                        {(t(`featuresOwners.featureslist`, { returnObjects: true })).map((feature, index) => {
                                            return (
                                                <div className='unstyled-list-item d-flex align-items-center' key={index}>
                                                    <div className='unstyled-list-item-icon'></div>
                                                    <span className='unstyled-list-item-text'>
                                                        <Trans i18nKey={`featuresOwners.featureslist.${index}`} />
                                                    </span>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </Container>
                </div>

                <div className='wrapper-section-features'>
                    <Container className='container-page-aligned'>
                        <div className='section-header'>
                            <p className="section-title features-sub1"><strong>{t(`featuresOwners.subtitle-3`)} <br />
                                <span>{t(`featuresOwners.subtitle-3-1`)}</span></strong></p>
                            <div className="features-sub2">
                                {t(`featuresOwners.subtitle-3-2-desktop`)}
                            </div>
                        </div>

                        <div className='row g-0 wrapper-section-features-features'>
                            <div className='col'>
                                <Features features={t(`featuresOwners.features`, { returnObjects: true })}>
                                </Features>
                            </div>
                        </div>
                    </Container>
                </div>


                <Steps2Container
                    steps={t(`stepsOwners.steps`, { returnObjects: true })}
                    title={<Trans i18nKey={`stepsOwners.title`} />}
                    colXL='col-xl-3'
                    subtitle=""
                />

                <div className="section-costs" id="services">
                    <Container className='container-page-aligned'>

                        <div className='services-header-mobile'>
                            <h2 className="section-title"><Trans i18nKey={`servicesOwners.title`} /></h2>
                            <div className='section-title-info'>
                                <Trans i18nKey={`servicesOwners.titleInfo`} />
                            </div>

                            <div className='section-title-explore'>
                                <Trans i18nKey={`servicesOwners.explore`} />
                            </div>
                        </div>

                        <div className={`table-services ${props.tableActiveColumn}`}>

                            <div className="row-head">
                                <div className="col-row">
                                    <div className='services-header-desktop'>
                                        <h2 className="section-title"><Trans i18nKey={`servicesOwners.title`} /></h2>
                                        <div className='section-title-info'>
                                            <Trans i18nKey={`servicesOwners.titleInfo`} />
                                        </div>

                                        <div className='section-title-explore'>
                                            <Trans i18nKey={`servicesOwners.explore`} />
                                        </div>
                                    </div>
                                </div>

                                {(t(`servicesOwners.categories`, { returnObjects: true })).map((col, catIndex) => {
                                    return (
                                        <div key={catIndex} className={`col-row ${getActiveClass(catIndex + 2)}`} onClick={() => props.handleSetTableActiveColumn(`active-${catIndex + 2}`)}>
                                            <div className={`cell-content plan-header ${getActiveClass(catIndex + 2)}`}>

                                                <h2 className="plan-title">
                                                    <span dangerouslySetInnerHTML={{ __html: col.title }}></span>
                                                </h2>
                                                <div className='plan-fees'>
                                                    {col.fees.map((category, index) => {
                                                        return (
                                                            <div key={index} className='fee'>
                                                                <Modal show={props.showModalFee[catIndex].fees[index].modalInfo.open} onHide={() => props.handleCloseModalFee(catIndex, index)}
                                                                    className="custom-modal" centered scrollable>
                                                                    <Modal.Header closeButton className="header-modal">
                                                                        <h2 className="section-title small-header">
                                                                            <strong dangerouslySetInnerHTML={{ __html: category.title }}>
                                                                            </strong>
                                                                            &nbsp;<span dangerouslySetInnerHTML={{ __html: category.description }}></span>
                                                                        </h2>
                                                                    </Modal.Header>
                                                                    <p dangerouslySetInnerHTML={{ __html: category.modalInfo.description }} />
                                                                </Modal>
                                                                <div className='fee-title'>
                                                                    <span>
                                                                        {category.title}
                                                                        <span className='text-nowrap w-auto'>&#65279;</span>
                                                                    </span>
                                                                    <button className="unstyled-button info-btn"
                                                                        onClick={() => props.handleShowModalFee(catIndex, index)}>
                                                                        <InfoIcon className="info-icon" />
                                                                    </button>
                                                                </div>
                                                                <div className='fee-description'>
                                                                    <span dangerouslySetInnerHTML={{ __html: category.description }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <MainButton href={col.button.link} title={col.button.title} color={getButtonPlanClass(catIndex + 2)} />
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>

                            {(t(`servicesOwners.options`, { returnObjects: true })).map((option, index) => {
                                return (
                                    <div key={index} className="row-table">
                                        <Modal show={props.showModal[index].modalInfo.open} onHide={() => props.handleCloseModal(index)}
                                            className="custom-modal step-modal" centered scrollable>
                                            <Modal.Header closeButton className="header-modal">
                                                <h2 className="section-title small-header"><strong>{option.title} </strong></h2>
                                            </Modal.Header>
                                            {/* <p><Trans i18nKey={option.modalInfo.description} /></p> */}
                                            <p dangerouslySetInnerHTML={{ __html: option.modalInfo.description }} />

                                        </Modal>

                                        <div className="col-row">
                                            <div className='cell-content plan-attribute-name'>
                                                <p className="option-title">
                                                    {option.title}<span className='text-nowrap'>&#65279;<button className="unstyled-button info-btn"
                                                        onClick={() => props.handleShowModal(index)}>
                                                        {/* <img src={iconInfo} alt="" /> */}
                                                        <InfoIcon />
                                                    </button></span></p>
                                            </div>
                                        </div>
                                        <div className={`col-row ${getActiveClass(2)}`} onClick={() => props.handleSetTableActiveColumn('active-2')}>
                                            <div className={`plan-attribute-value cell-content ${getActiveClass(2)}`}>
                                                {
                                                    option.plan1 &&
                                                    <img src={iconCheck} alt='' />
                                                }
                                                {
                                                    !option.plan1 &&
                                                    <CrossIcon />
                                                }
                                                {/* <img src={option.plan1 ? iconCheck : iconNo} alt="" /> */}
                                            </div>
                                        </div>
                                        <div className={`col-row ${getActiveClass(3)}`} onClick={() => props.handleSetTableActiveColumn('active-3')}>
                                            <div className={`plan-attribute-value cell-content ${getActiveClass(3)}`}>
                                                {/* <img src={option.plan2 ? iconCheck : iconNo} alt="" /> */}
                                                {
                                                    option.plan2 &&
                                                    <img src={iconCheck} alt='' />
                                                }
                                                {
                                                    !option.plan2 &&
                                                    <CrossIcon />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}


                            <div className="row-footer">
                                <div className="col-row" />
                                {(t(`servicesOwners.categories`, { returnObjects: true })).map((col, catIndex) => {
                                    return (
                                        <div key={catIndex} className={`col-row ${getActiveClass(catIndex + 2)}`} onClick={() => props.handleSetTableActiveColumn(`active-${catIndex + 2}`)}>
                                            <div className={`plan-footer cell-content ${getActiveClass(catIndex + 2)}`}>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Container>
                </div>


                <div className="wrapper-section-evaluate" >

                    <Container className='container-page-aligned'>
                        <div className='row g-0'>

                            <div className='col-12 col-lg-6'>
                                <div className='section-evaluate-img'>
                                         <img src={img_evaluate} alt="" />
                                      {/* <img src={img_landlords_room} alt="" /> */}
                                </div>
                            </div>

                            <div className='col-12 col-lg-6'>
                                <div className='section-evaluate-info'>
                                    <div className='section-evaluate-info-title'>
                                        <h2 className="section-title">
                                            <Trans i18nKey={`servicesOwners.title2`} />
                                        </h2>
                                    </div>

                                    <div className='section-evaluate-info-p1'>
                                        <Trans i18nKey={`featuresOwners.subtitle-4`} />
                                    </div>

                                    <div className='section-evaluate-info-p2'>
                                        <Trans i18nKey={`featuresOwners.subtitle-4b`} />
                                    </div>


                                    <MainButton onClick={log} title={t(`bannerOwners.button.title`)} />

                                </div>
                            </div>

                        </div>

                    </Container>
                </div>


                {/* {(AppModelService.getCountry() === 'ro') &&
                    <SliderTestimonials testimonials={t(`reviewsOwners.reviews`, { returnObjects: true })}>
                        <Trans i18nKey={`reviewsOwners.title`} />
                    </SliderTestimonials>
                } */}

                <WhatIsMilluu />

                <News />

                <div className='float-container'>
                    <ContactUsComponent />
                </div>

            </div>
        </React.Fragment>

    );
};

const CrossIcon = () => {
    return (
        <svg width="25" height="25" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5">
                <path d="M1.5 1.31934L14 13.8194M14 13.8194L26.4999 26.3193M14 13.8194L26.5 1.31934M14 13.8194L1.50006 26.3193" stroke="currentColor" strokeWidth="2" stroke-linejoin="round" />
            </g>
        </svg>
    )
}

const InfoIcon2 = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <defs></defs>
            <g id="Layer_2" data-name="Layer 2">
                <g id="Layer_1-2" data-name="Layer 1">
                    <path fill="none" d="M0,8a8,8,0,1,1,8,8A8,8,0,0,1,0,8Z" />
                    <path fill="currentColor" d="M8,5.16a.85.85,0,0,0,.88-.86A.82.82,0,0,0,8,3.51.83.83,0,1,0,8,5.16ZM7.31,12H8.69V6.13H7.31Z" />
                    <path fill="currentColor" d="M8,14.22A6.21,6.21,0,0,1,1.78,8H0a8,8,0,0,0,8,8ZM14.22,8A6.21,6.21,0,0,1,8,14.22V16a8,8,0,0,0,8-8ZM8,1.78A6.21,6.21,0,0,1,14.22,8H16A8,8,0,0,0,8,0ZM8,0A8,8,0,0,0,0,8H1.78A6.21,6.21,0,0,1,8,1.78Z" />
                </g>
            </g>
        </svg>
    )
}

const InfoIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" heigh="16" viewBox="0 0 16 16" className={`info-icon-component ${props.className}`} >
            <use href={`${iconInfo}#icon`} />
        </svg>
    )
}


export default OwnersComponent;
