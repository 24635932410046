import React from 'react';

import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";


import './Steps2.scss';

const Steps2Component = (props) => {
    const { t } = useTranslation();


    return (
        <div className={`steps2-component`}>

            <Container className='container-page-aligned'>
                <div className='section-header'>
                    <p className="section-title features-sub1"><strong>{props.title}
                        {
                            props.title2 &&
                            (
                                <React.Fragment>
                                    <br />
                                    <span>{props.title2}</span>
                                </React.Fragment>)
                        }
                    </strong></p>
                    {
                        props.subtitle &&
                        <div className="features-sub2">
                            {props.subtitle}
                        </div>
                    }
                </div>

                <div className='row g-0 wrapper-section-features-features'>
                    <div className='col'>
                        <div className='row gy-4 steps-table'>
                            {props.steps.map((step, index) => {
                                return (
                                    <div key={index} className={`col-12 col-md-6 ${props.colXL} steps-col`}>

                                        <div className='step-container'>

                                            <div className='step-number'>
                                                <div>{index + 1}</div>
                                            </div>

                                            <div className='step-box'>

                                                <div className='step-title'>
                                                    <p dangerouslySetInnerHTML={{ __html: step.title }} />
                                                </div>

                                                <div className='step-description'>
                                                    <p dangerouslySetInnerHTML={{ __html: step.modalInfo.modalDescription }}  />
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>


                    </div>
                </div>
            </Container>


        </div>
    );
};

export default Steps2Component;