import React from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import logo from '../../resources/img/logo.svg';
import iconFacebook from './img/icon-facebook.svg';
import iconLinkedin from './img/icon-linkedin.svg';
import iconInstagram from './img/icon-instagram.svg';


import { AppModelService } from '../../AppModel';

import './Footer.scss';

const FooterComponent = () => {

    const { t } = useTranslation();

    return (
        <div className="footer-component">
            <Container className='container-page-aligned'>
                <div className='row footer-content-container g-0' >
                    <div className="footer-logo-wrapper d-flex justify-content-center justify-content-md-start align-items-center">
                        <img className="footer-logo" src={logo} alt="" />
                    </div>
                    <Row className="g-0">
                        <div className="order-2 col-12 col-md-auto d-flex flex-column align-items-center align-items-md-end justify-content-end">
                            {(AppModelService.getCountry() === 'ro') &&
                                <ul className="pt-4 pt-md-0 socials d-lg-flex justify-content-lg-end unstyled-list d-none">
                                    <li><a href="https://facebook.com/milluuapp/" rel="noreferrer" title="Facebook" target="_blank"><img src={iconFacebook} alt="Facebook" /></a></li>
                                    <li><a href="https://linkedin.com/company/milluu" rel="noreferrer" title="LinkedIn" target="_blank"><img src={iconLinkedin} alt="LinkedIn" /></a></li>
                                    <li><a href="https://instagram.com/milluu_app/" rel="noreferrer" title="Instagram" target="_blank"><img src={iconInstagram} alt="Instagram" /></a></li>
                                </ul>
                            }
                            <ul className="links-menu d-flex  unstyled-list pt-4 pt-md-0 ">
                                {(t(`footer.menuLinks`, { returnObjects: true })).map((link, index) => {
                                    return link.external === true ? (
                                        <li key={index}><Link to={{ pathname: link.link }} target="_blank">{link.title}</Link></li>
                                    ) : (
                                        <li key={index}><Link to={link.link}>{link.title}</Link></li>
                                    )
                                })}
                            </ul>
                        </div>
                        <Col className="order-1 col d-flex flex-column align-items-center align-items-md-start">
                            <p>2024© Millluu. {t(`more.allrights`)}</p>
                            <ul className="terms-menu d-flex flex-wrap unstyled-list">
                                {(t(`footer.termsLinks`, { returnObjects: true })).map((link, index) => {
                                    return <li key={index}><Link to={{ pathname: link.link }} target="_blank">{link.title}</Link></li>
                                })}
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default FooterComponent;