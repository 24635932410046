import React from 'react';
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Dropdown from "react-bootstrap/Dropdown";

import logo from '../../resources/img/logo.svg';
import iconAndroid from './img/icon-android.svg';
import iconLanguage from './img/icon-language.svg';
import iconIos from './img/icon-ios.svg';
import {AppModelService, lngs, countries } from "../../AppModel.js";


import './Header.scss';

import { useHistory  } from "react-router-dom";
import { useState } from 'react';
//import { DropdownDivider, DropdownItem } from 'react-bootstrap';


const HeaderComponent = () => {
    const {i18n} = useTranslation();
    const {t} = useTranslation();
    const history = useHistory ();
    const [expanded, setExpanded] = useState(false);
    const [collapsed, setCollapsed] = useState(null);


    const changeCountry = (x) => {
        AppModelService.changeCountry(x, i18n);
        //
    };

    const changeLanguage= (x) => {
        console.log("change language: " + x);
        i18n.changeLanguage(x);
    };

    const isLngVisible = (lng) => {
        if(!lngs[lng].country)
            return true;
        return lngs[lng].country ===  AppModelService.getCountry();
    };

    const onToggle = (value) => {
        setExpanded(value);
        setCollapsed(!value);
    }

    return (
        <div className={`header-component ${AppModelService.getCountry() === 'ro'? "header-component-ro" : "header-component-pl"}`}>
            <Container>
                <Row>
                    <Col>
                        <Navbar expand="md" collapseOnSelect onToggle={onToggle}
                            className={`main-nav ${expanded ? "expanded" : ""} ${collapsed ? "collapsed" : ""}`
                            }>
                            <Navbar.Brand>
                                <Link to="/"><img src={logo} alt=""/></Link>
                            </Navbar.Brand>

                            <Navbar.Toggle aria-controls="basic-navbar-nav"/>

                            <Navbar.Collapse id="basic-navbar-nav" className={collapsed ? "collapsed" : ""}>
                                <Nav className="menu-items" >
                                    <div className="links milluu-navigation-links" > {/* add class active on link-nav */}
                                        {(t(`menu.links`, { returnObjects: true }))
                                            .filter(x => {
                                                if (x.link === '/parteneri') {
                                                    return false;
                                                }
                                                return true;
                                            })
                                            .map((link, index) => {
                                            return <Nav.Link key={index} eventKey={index} className="link-nav" as={Link} to={link.link}>{link.title}</Nav.Link>
                                        })}
                                    </div>
                                    <div className="links">
                                        <a className="link-app" href={AppModelService.getGooleAppLink()} >
                                            <img src={iconAndroid} alt=""/>
                                            <span className='link-app-text'>Download Android App</span>
                                            </a>
                                        <a className="link-app" href={AppModelService.getIosAppLink()} >
                                            <img src={iconIos} alt=""/>
                                            <span className='link-app-text'>Download Ios App</span>
                                        </a>
                                    </div>
                                    <div className="links">
                                        <Dropdown  className='dropdown-milluu'>
                                            <Dropdown.Toggle variant="link" id="dropdown-country" className="dropdown-milluu-btn mr-16">
                                                {Object.keys(countries).map((cnt) => (
                                                    <div key={cnt} className={`lng-drop-down-toggle ${ AppModelService.getCountry() !== cnt ? 'd-none' : ''}`} >
                                                        <div className='lng-select-text'>Select your region:</div>
                                                        <div className={`flag-wrapper `}>
                                                            <img src={countries[cnt].icon} alt=""/>
                                                        </div>
                                                        <span className="span-lng ms-2">{countries[cnt].shortName}</span>
                                                    </div>
                                                ))}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='dropdown-menu-country'>
                                                <Dropdown.Divider className='dropdown-divider-language'></Dropdown.Divider>
                                                <Dropdown.Header className='dropdown-header-language'>Select your region</Dropdown.Header>
                                                {Object.keys(countries).map((cnt) => (
                                                    <Dropdown.Item key={cnt}
                                                                className={AppModelService.getCountry() === cnt && 'selected'}
                                                                type="submit"
                                                                onClick={() => { changeCountry(cnt) }}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flag-wrapper">
                                                                <img src={countries[cnt].icon} alt=""/>
                                                            </div>
                                                            <span className="span-lng ms-2">{countries[cnt].nativeName}</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                                <Dropdown.Divider className='dropdown-divider-language'></Dropdown.Divider>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>

                                    <div className="links">
                                        <Dropdown className='dropdown-milluu'>
                                            <Dropdown.Toggle variant="link" id="dropdown-toggle-btn-id" className="dropdown-milluu-btn">
                                                {Object.keys(lngs).map((lng) => (
                                                    <div key={lng} className={`lng-drop-down-toggle ${i18n.language !== lng ? 'd-none' : ''}`}>
                                                        <div className='lng-select-text'>Select your language:</div>
                                                        <img src={iconLanguage} alt="" className='language-icon'/>
                                                        <span className='span-lng'>{lngs[lng].shortName}</span>
                                                    </div>
                                                ))}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu className='dropdown-menu-language'>
                                                <Dropdown.Divider className='dropdown-divider-language'></Dropdown.Divider>
                                                <Dropdown.Header className='dropdown-header-language'>Select your language</Dropdown.Header>
                                                {Object.keys(lngs).map((lng) => (
                                                    <Dropdown.Item key={lng}
                                                                className={ i18n.language == lng  ?  'selected' : ( !isLngVisible(lng) ? 'd-none' :  '')}
                                                                type="submit"
                                                                onClick={() => changeLanguage(lng)}>
                                                        <div className="d-flex align-items-center">
                                                            <div className="flag-wrapper">
                                                                <img src={lngs[lng]?.icon} alt=""/>
                                                            </div>
                                                            <span className='span-lng ms-2'>{lngs[lng].nativeName}</span>
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                                <Dropdown.Divider className='dropdown-divider-language'></Dropdown.Divider>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default HeaderComponent;