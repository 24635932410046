import React from 'react';

import FooterComponent from "./FooterComponent";

const FooterContainer = () => {
    return (
        <FooterComponent />
    );
};

export default FooterContainer;