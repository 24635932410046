import React from 'react';
import {useTranslation, Trans} from "react-i18next";

import MainButton from "../../components/main-button/MainButton";
import FeaturesSection from '../../components/features-section/FeaturesSection';
import Steps2Container from "../../components/steps2/Steps2Container";
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import News from "../../components/news/News";

import ico_partners from './img/partners_1500.jpeg';

import Container from "react-bootstrap/Container";

import '../../resources/styles/secondary-lp.scss';
import './Partners.scss';
import { AppModelService } from '../../AppModel';
import { useHistory  } from "react-router-dom";

const PartnersComponent = (props) => {

    const {t} = useTranslation();

    function log() {
    }

    const history = useHistory ();
    if (AppModelService.getCountry() === 'pl') {
        history.replace("/");
        return (<div></div>);
    }
    else
    return (
        <React.Fragment>
            <div className="partnersV2-component">

                <Container className="container-page-aligned wrapper-section-hero">
                    <div className='row g-0'>
                        <div className='col-12 col-md-6'>
                            <div className="wrapper-hero-description">
                                <div>
                                    <h2 className="tenant-h1"><Trans i18nKey={`bannerPartnersH1.title1`} /></h2>
                                    <h2 className="tenant-h2"><Trans i18nKey={`bannerPartnersH1.title2`} /></h2>
                                    <h2 className="tenant-h3"><Trans i18nKey={`bannerPartnersH1.title3`} /></h2>

                                    <MainButton color="white-button milluu-w-100 milluu-w-md-auto" href={t(`bannerPartners.button.link`)} title={t(`bannerPartnersH1.button.title`)} />

                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='wrapper-hero-img'>
                                <img src={ico_partners} alt='' />
                            </div>
                        </div>
                    </div>
                </Container>

                <FeaturesSection
                    title={t(`featuresPartners.title`)}
                    subtitle={t(`featuresPartners.subtitle`)}
                    features={t(`featuresPartners.features`, { returnObjects: true })}>
                </FeaturesSection>


                <Steps2Container
                        steps={t(`stepsPartners.steps`, { returnObjects: true })}
                        title={<Trans i18nKey={`stepsPartners.title`} />}
                        colXL='col-xl-4'
                        subtitle={<Trans i18nKey={`stepsPartners.subtitle`} />}
                />

                <WhatIsMilluu />

                <News />
            </div>
        </React.Fragment>
    );
};

export default PartnersComponent;