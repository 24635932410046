import React from 'react';
import {
    Switch,
    Route
} from "react-router-dom";

import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import HomeContainer from "./containers/home/HomeContainer";
import OwnersContainer from "./containers/owners/OwnersContainer";
import TenantsContainer from "./containers/tenants/TenantsContainer";
import PartnersContainer from "./containers/partners/PartnersContainer";
import InvitationComponent from './containers/Invitation/InvitationComponent';
import ContactContainer from "./containers/contact/ContactContainer";
//import RedirectionComponent from './containers/rent-offer-deeplink/redirectionComponent';

const Routes = (props) => {
    return (
        <>
            <ScrollToTop />
            <Switch>
                <Route exact path="/">
                    <HomeContainer />
                </Route>
                
                <Route exact path="/proprietari">
                    <OwnersContainer />
                </Route>
                <Route exact path="/właścicieli">
                    <OwnersContainer />
                </Route>
                <Route exact path="/wlascicieli">
                    <OwnersContainer />
                </Route>
                <Route exact path="/wlasciciele">
                    <OwnersContainer />
                </Route>
                
                <Route exact path="/chiriasi">
                    <TenantsContainer />
                </Route>
                <Route exact path="/najemców">
                    <TenantsContainer />
                </Route>
                <Route exact path="/najemcow">
                    <TenantsContainer />
                </Route>
                <Route exact path="/najemcy">
                    <TenantsContainer />
                </Route>

                <Route exact path="/parteneri">
                    <PartnersContainer />
                </Route>
                <Route exact path="/partnerzy">
                    <PartnersContainer />
                </Route>

                <Route exact path="/contact">
                    <ContactContainer />
                </Route>

                <Route path="/invitation/:id" component={InvitationComponent}>
                </Route>
               
            </Switch>
            
        </>
    );
};

// function Child() {
//     let { id } = useParams();
//     console.log ({id});
// }

export default Routes;