import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import LanguageDetector from 'i18next-browser-languagedetector';

import translationRO from './locales/ro/translation.json';
import translationPL from './locales/pl/translation.json';
import translationENPL from './locales/en_pl/translation.json';
import translationENRO from './locales/en_ro/translation.json';

const options = {
	// order and from where user language should be detected
	order: ['localStorage','navigator','querystring', 'cookie', 'sessionStorage', 'htmlTag', 'path', 'subdomain'],
  
	// keys or params to lookup language from
	lookupQuerystring: 'lng',
	lookupCookie: 'i18next',
	lookupLocalStorage: 'i18nextLng',
	lookupSessionStorage: 'i18nextLng',
	lookupFromPathIndex: 0,
	lookupFromSubdomainIndex: 0,
  
	// cache user language on
	caches: ['localStorage', 'cookie'],
	excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  
	// optional expire and domain for set cookie
	cookieMinutes: 10,
	cookieDomain: 'myDomain',
  
	// optional htmlTag with lang attribute, the default is:
	htmlTag: document.documentElement,
  
	// optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
	cookieOptions: { path: '/', sameSite: 'strict' },
	debug: true,
	 
  }
// the translations
const resources = {
	en_pl: {
		translation: translationENPL
	},
	en_ro: {
		translation: translationENRO
	},
	
	ro: {
		translation: translationRO
	},

	pl: {
		translation: translationPL
	}
	
};

const startCountry = (process?.env?.REACT_APP_COUNTRY  ?? '').toLowerCase();

const country = startCountry ? startCountry : (window.location.hostname.indexOf(".pl") >= 0 ? 'pl' : 'ro');
const queryParams = new URLSearchParams(window.location.search);

var lng = queryParams.has('lng') ? queryParams.get('lng') : null;
if (lng) {
	lng = lng.toLocaleLowerCase();
}

if (lng === 'en') {
	lng = lng + '_' + country;
}
else {
	if (lng != country) {
		lng = country;
	}
}

if (!lng || !resources[lng]) {
	lng = country;
}


i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
	  debug: false,
	  detection: options,
	  lng:  lng,
	  //fallbackLng: 'en',
	  interpolation: {
		  escapeValue: false, // not needed for react as it escapes by default
	  },
	  resources
  });
  

export default i18n;	