import React, {useEffect, useState}  from 'react';
import {Trans} from "react-i18next";


import Container from "react-bootstrap/Container";
import moment from 'moment';
import btnAndroid from './img/btn-android.svg';
import btnIos from './img/btn-ios.svg';
import axios from 'axios';


import './Invitation.scss';
import { AppModelService } from '../../AppModel';


const HomeComponent = (props) => {
    
    let _data = {
        'token':props.match.params.id
    };

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([])

    let status;
    let dataFinal;


    
    useEffect( () => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const apiEndpoint = AppModelService.currentSettings().backendUri;
                const {data: response} = await axios.post(`${apiEndpoint}/visit-partner/confirm-invitation.json`, _data);
                setData(response);
            } catch (error) {
               console.log(error.response.data.error.message);
            }
            setLoading(false);
        }
        fetchData();
    }, []);
          
if(data.length > 0) {
         data.map((item, index) => {
            if(item.existing) {
                // vizita existenta
                status = 'existing';
                return null;
             }
            if(item.status == 3 && (moment(item.expires_at) < moment())) {
                // invitatie expirata
                status = 'expired';
                return null;
            }
            if( item.status == 3) {
                //invitatie invalida
                status = 'invalid';
                return null;
            }
            if(item.status == 2) {
               //invitatie acceptata, afiseaza ora si confirmarea
               status = 'ok';
               dataFinal = item;
               return null;
           }
         });
          
       
} else if(!loading && data.length == 0) {
    //return empty array invalid invitation or already been accepted
    return (
        <div className="home-component">
            <div className="section-cta">
                <Container>
                    <div className="row-cta d-flex flex-wrap">
                        <div className="col">
                            <div className="box-wrapper">
                                <div className="description">
                                    <h2 className="section-title align-self-center"> 
                                        <Trans i18nKey={`callToActionInvite.invalid`}/> 
                                    </h2>
                                        <div className="app-row">
                                            <div className="buttons-group">
                                                <a href={AppModelService.getGooleAppLink()}><img className="android-btn" src={btnAndroid} alt=""/></a>
                                                <a href={AppModelService.getIosAppLink()}><img className="apple-btn" src={btnIos} alt=""/></a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
        );

} else {
    return (
      
                <div>
                <div className="home-component">
                    <div className="section-cta">
                        <Container>
                            <div className="row-cta d-flex flex-wrap">
                            <div className="col">
                                <div className="box-wrapper">
                                    <div className="description">
                                        <h2 className="section-title align-self-center"> 
                                                    <div>
                                                        <img src="https://res.cloudinary.com/dxbxgzdl6/image/upload/v1636654499/1480.gif"/>
                                                     </div>                                        
                                        </h2>
                                        <div className="app-row">
                                            <div className="buttons-group">
                                                <a href={AppModelService.getGooleAppLink()}><img className="android-btn" src={btnAndroid} alt=""/></a>
                                                <a href={AppModelService.getIosAppLink()}><img className="apple-btn" src={btnIos} alt=""/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            </div>
                        </Container>
                    </div>
                </div>
            </div>
    )
}


if(status =='ok') {
    return (
        <div>
            <div className="home-component">
                <div className="section-cta">
                    <Container>
                        <div className="row-cta d-flex flex-wrap">
                            <div className="col">
                                <div className="box-wrapper">
                                    <div className="description">
                                        <h2 className="section-title align-self-center"> 
                                            <Trans i18nKey={`callToActionInvite.active1`}/> {dataFinal.address}  <Trans i18nKey={`callToActionInvite.active1-2`}/>
                                            <br/>{moment(dataFinal.date).format('DD/MM/YYY [-] HH:mm')} <br/>
                                            <Trans i18nKey={`callToActionInvite.active2`}/>

                                        </h2>
                                        <div className="app-row">
                                            <div className="buttons-group">
                                                <a href={AppModelService.getGooleAppLink()}><img className="android-btn" src={btnAndroid} alt=""/></a>
                                                <a href={AppModelService.getIosAppLink()}><img className="apple-btn" src={btnIos} alt=""/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Container>
                </div>
            </div>
        </div>

    );

}  else if (status == 'existing') {
    return (
        <div className="home-component">
            <div className="section-cta">
                <Container>
                    <div className="row-cta d-flex flex-wrap">
                        <div className="col">
                            <div className="box-wrapper">
                                <div className="description">
                                    <h2 className="section-title align-self-center"> 
                                       Exista deja o vizita confirmata la acea data si ora
                                    </h2>
                                    <div className="app-row">
                                                <div className="buttons-group">
                                                    <a href={AppModelService.getGooleAppLink()}><img className="android-btn" src={btnAndroid} alt=""/></a>
                                                    <a href={AppModelService.getIosAppLink()}><img className="apple-btn" src={btnIos} alt=""/></a>
                                                </div>
                                            </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
        ); 
}

else if(status == 'expired') {
    return (
    <div className="home-component">
        <div className="section-cta">
            <Container>
                <div className="row-cta d-flex flex-wrap">
                    <div className="col">
                        <div className="box-wrapper">
                            <div className="description">
                                <h2 className="section-title align-self-center"> 
                                    <Trans i18nKey={`callToActionInvite.inactive`}/> 
                                </h2>
                                <div className="app-row">
                                            <div className="buttons-group">
                                                <a href={AppModelService.getGooleAppLink()}><img className="android-btn" src={btnAndroid} alt=""/></a>
                                                <a href={AppModelService.getIosAppLink()}><img className="apple-btn" src={btnIos} alt=""/></a>
                                            </div>
                                        </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    </div>
    );
} else if(status == 'invalid') {
    return (
        <div className="home-component">
            <div className="section-cta">
                <Container>
                    <div className="row-cta d-flex flex-wrap">
                        <div className="col">
                            <div className="box-wrapper">
                                <div className="description">
                                    <h2 className="section-title align-self-center"> 
                                        <Trans i18nKey={`callToActionInvite.invalid`}/> 
                                    </h2>
                                        <div className="app-row">
                                            <div className="buttons-group">
                                                <a href={AppModelService.getGooleAppLink()}><img className="android-btn" src={btnAndroid} alt=""/></a>
                                                <a href={AppModelService.getIosAppLink()}><img className="apple-btn" src={btnIos} alt=""/></a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
        );
}

   

  

// return (
//     <div>
//     <div className="home-component">
//     <div className="section-cta">

//         <Container>
//             <div className="row-cta d-flex flex-wrap">
//                 <div className="col">
//                     <div className="box-wrapper">

//                         <div className="description">
//                          <div>

//                          {!loading && data.length > 0  && (
//   <h2 className="section-title">   

//       <div>
//         {data.map((item, index) => (
//         <p className="addr">
//             {moment(item.expires_at) < moment() &&  (
//                                 <p>Invitatia a expirat ( status 3 si data a trecut)</p>
//                         //  <h2 className="section-title"> 
//                         //  <Trans i18nKey={`callToActionInvite.inactive`}/>
//                         //  <Trans i18nKey={`callToActionInvite.inactive2`}/>
//                         //  </h2>
//                          ) } 

//             {/* {item.status == 3 && (<div>Vizita din {item.address} a fost anulata. </div>)} */}

//             {/* <Trans i18nKey={`callToActionInvite.active1`}/>
//             {item.address}
//             <Trans i18nKey={`callToActionInvite.active1-2`}/>{moment(item.date).format('DD/MM/YYY [-] HH:mm')} */}
//         </p>
            
//             ))
//             }
//       </div>

// {/* <p><Trans i18nKey={`callToActionInvite.active2`}/></p> */}

//  </h2> 
//     )}
//        </div>
//                 {data.length == 0 && <div>
//                     <h2 className="section-title"> 
//                             {/* <Trans i18nKey={`callToActionInvite.existing`}/> */}
//                             Ne pare rau , invalida, Descarca aplicatia etc.
//                             </h2>
//                         </div>
//                          }
                      
//                             <div className="app-row">
//                             <div className="buttons-group">
//                     <a href="https://play.google.com/store/apps/details?id=com.stardevelopment.milluu&hl=ro"><img className="android-btn" src={btnAndroid} alt=""/></a>
//                     <a href="https://apps.apple.com/app/id1466056170?l=ro"><img className="apple-btn" src={btnIos} alt=""/></a>
//                 </div>
//                             </div>
                            

           
//                         </div>
//                     </div>
//                 </div>

//             </div>
//         </Container>
//     </div>
// </div>
// </div>

// );


};

export default HomeComponent;