import React from 'react';
import { useTranslation, Trans } from "react-i18next";

import { useState } from 'react';

import '../../resources/styles/secondary-lp.scss';
import './Contact.scss';

//import icon_mail from './img/icon_mail.png';
//import contact_photo from './img/contact_1024.png';
import contact_photo from './img/contact_1024_90.webp';

import Container from "react-bootstrap/Container";

import { AppModelService } from '../../AppModel';

const ContactComponent = (props) => {

    const { t } = useTranslation();

    //const { register, handleSubmit, reset } = useForm();



    //const { register, handleSubmit } = useForm();
    let [result, setResult] = useState({ done: false, success: false, text: 'xx' });
    let [data, setData] = useState({})

    const getTextResult = () => {
        return result ? result.text : 'no result';
    }

    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        data[name] = value;

        setData(data);

        //console.log(data);
        if (result.done && !result.saving) {
             setResult({ done: false, success: false, text: '' });
        }
    }
    const reset = () => {
        try {
            document.getElementById("contact-milluu-form")["name"].value = '';
            document.getElementById("contact-milluu-form")["email"].value = '';
            document.getElementById("contact-milluu-form")["message"].value = '';
            document.getElementById("contact-milluu-form")["mobile"].value = '';
            document.getElementById("contact-milluu-form")["terms"].value = '';
        }
        catch (error) {
            console.error(error);
        }
    }

    function test() {
        setResult({ done: true, success: false, text: t('contact.message_not_sent') });
    }

    const onSubmit = (d) => {
        d.preventDefault();
        //console.log(data);

        if (result.saving) {
            console.log('skip');
            return;
        }

        setResult({ done: false, saving: true });

        let formData = new FormData();
        formData.append('nume', data.name);
        formData.append('email', data.email);
        formData.append('mesaj', data.message);
        formData.append('mobile', data.mobile);
        formData.append('terms', data.terms);

        setResult({ done: false, success: false, text: '' });

        //fetch('w/emailsend-milluu.php', {
        fetch( AppModelService.getCountry() == 'pl' ?  '/w/emailsend-milluu.php' : '/emailsend-milluu.php', {
            method: 'POST',
            headers: {
                //'Content-Type': 'multipart/form-data',
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: formData
        })
            .then((response) => {

                response.json()
                    .then((responseData) => {

                        let success = (responseData && (responseData.type == 'success' || responseData.type == 'succes') ) ? true : false;
                        let text = '';

                        if (responseData.error_code) {
                            text = t('contact.error_' + responseData.error_code) || text;
                        }
                        else {
                            if (!success) {
                                text = t('contact.message_not_sent');
                            }
                            else {
                                text = t('contact.message_sent');
                            }
                        }

                        setResult({ done: true, success: success, text: text });
                        if (success) {
                            reset();
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                        setResult({ done: true, success: false, text: t('contact.message_not_sent') });
                    })
                    .finally(() => {

                    })

            })
            .catch((error) => {
                console.error(error);
                setResult({ done: true, success: false, text: t('contact.message_not_sent') });
            });
    }


    return (
        <div className='contactV2-component'>
            <Container className='container-page-aligned'>
                <div className='row gy-4'>
                    <div className='col-lg-4 col-md-12'>
                        <div className='normal-text mb-4'>{t('contactV2.pretitle')}</div>
                        <span className='big-text'>{t('contactV2.title')}</span>
                        <div className='description-text mt-regular'>{t('contactV2.titleDescription')}</div>

                        <div className='row gx-2 gy-2 mt-regular'>
                            <div className='col-lg-12 col-md-6 col-sm-12'>
                                <div className='contact-section '>
                                    <div className='title'>{t('contactV2.support.title')}</div>

                                    <a href={'mailto:'+t('contactV2.support.email')}>
                                        <div className='d-flex align-items-center mt-4'>
                                            <div className='contact-icon'>
                                                <svg width="25" height="25" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.29 24.2991C17.5307 25.9064 14.1479 26.4642 10.812 25.5841C4.13849 23.8233 0.155861 16.9859 1.91654 10.3122C3.67723 3.63856 10.5145 -0.344172 17.188 1.41655C23.8615 3.17728 27.8441 10.0147 26.0835 16.6884C25.631 18.4033 23.8741 19.4267 22.1593 18.9742C20.4445 18.5218 19.4211 16.7648 19.8735 15.05M19.8736 15.05C19.0177 18.294 15.6943 20.2299 12.4504 19.374C9.20653 18.5182 7.27065 15.1946 8.12649 11.9507C8.98232 8.70674 12.3058 6.77081 15.5497 7.62667C18.7935 8.48253 20.7294 11.8061 19.8736 15.05Z" stroke="#1D1D1D" stroke-width="2" stroke-linejoin="round"/></svg>
                                            </div>
                                            <div className='ms-4'>
                                                {t('contactV2.support.email')}
                                            </div>
                                        </div>
                                    </a>

                                    <a href={'tel:'+t('callToActionCall.phone')}>
                                        <div className='d-flex align-items-center mt-4'>
                                            <div className='contact-icon'>
                                                <svg width="25" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.16623 1L5.22051 2.9103H10.7947L11.849 1M4.16623 1H1.30078V26H14.7144V1H11.849M4.16623 1H11.849M11.0599 24.5465H4.99679" stroke="black" stroke-width="2" stroke-linejoin="round"/></svg>
                                            </div>
                                            <div className='ms-4'>
                                                {t('callToActionCall.phone')}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                            <div className='col-lg-12 col-md-6 col-sm-12'>
                                <div className='contact-section'>
                                    <div className='title'>{t('contactV2.sales.title')}</div>

                                    <a href={'mailto:'+t('contactV2.sales.email')}>
                                        <div className='d-flex align-items-center mt-4'>
                                            <div className='contact-icon'>
                                                <svg width="25" height="25" viewBox="0 0 28 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.29 24.2991C17.5307 25.9064 14.1479 26.4642 10.812 25.5841C4.13849 23.8233 0.155861 16.9859 1.91654 10.3122C3.67723 3.63856 10.5145 -0.344172 17.188 1.41655C23.8615 3.17728 27.8441 10.0147 26.0835 16.6884C25.631 18.4033 23.8741 19.4267 22.1593 18.9742C20.4445 18.5218 19.4211 16.7648 19.8735 15.05M19.8736 15.05C19.0177 18.294 15.6943 20.2299 12.4504 19.374C9.20653 18.5182 7.27065 15.1946 8.12649 11.9507C8.98232 8.70674 12.3058 6.77081 15.5497 7.62667C18.7935 8.48253 20.7294 11.8061 19.8736 15.05Z" stroke="#1D1D1D" stroke-width="2" stroke-linejoin="round"/></svg>
                                            </div>
                                            <div className='ms-4'>
                                                {t('contactV2.sales.email')}
                                            </div>
                                        </div>
                                    </a>

                                    <a href={'tel:'+t('callToActionCall.phone_sales')}>
                                        <div className='d-flex align-items-center mt-4'>
                                            <div className='contact-icon'>
                                                <svg width="25" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.16623 1L5.22051 2.9103H10.7947L11.849 1M4.16623 1H1.30078V26H14.7144V1H11.849M4.16623 1H11.849M11.0599 24.5465H4.99679" stroke="black" stroke-width="2" stroke-linejoin="round"/></svg>
                                            </div>
                                            <div className='ms-4'>
                                                {t('callToActionCall.phone_sales')}
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='col-lg-8 col-md-12'>

                        <div className='send-section '>

                                <div className='row gx-0'>
                                    <div className='col-lg-6 col-md-12'>
                                        <img className='image' src={contact_photo} alt=""/>
                                    </div>

                                    <div className='col-lg-6 col-md-12'>

                                        <div className='send-form '>

                                            <form onSubmit={onSubmit} id='contact-milluu-form' autoComplete="off">

                                                <div className='label-text'>{t('contactV2.name')}</div>
                                                <input className='input-text mt-small' type="text" name="name" id="fname"
                                                    required onChange={handleInputChange} />

                                                <div className='label-text mt-separator'>{t('contactV2.mobile')}</div>
                                                <input className='input-text mt-small' type="text" name="mobile" id="mobile"
                                                    required onChange={handleInputChange} />

                                                <div className='label-text mt-separator'>{t('contactV2.email')}</div>
                                                <input className='input-text mt-small' type="email" name="email" id="email_address"
                                                    required onChange={handleInputChange} />

                                                <div className='label-text mt-separator'>{t('contactV2.message')}</div>
                                                <textarea className='input-textarea mt-small' name="message" id="message"
                                                    required onChange={handleInputChange}></textarea>

                                                {/* <div className='d-flex flex-row mt-separator'>
                                                    <input className='input-check' type="checkbox" name="terms" id="terms"
                                                        required onChange={handleInputChange} />
                                                    <div className='small-text ms-2'>{t('contactV2.terms')}</div>
                                                </div> */}

                                                {/* <div className='w-100 mt-4 mb-3'>
                                                    <button type='button' onClick={test} className='submit-btn'>{t('contact.send')}</button>
                                                </div> */}

                                                <div className='w-100 mt-4 mb-3'>
                                                    <button type='submit' className='submit-btn'>{t('contact.send')}</button>
                                                </div>

                                            </form>


                                            <div className='result-container'>
                                                {
                                                    result && result.done && !result.success &&
                                                    <div className="text-danger">{getTextResult()}</div>
                                                }
                                                {
                                                    result && result.done && result.success &&
                                                    <div className="text-success">{t(getTextResult())}</div>
                                                }
                                            </div>

                                        </div>

                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
            </Container>

            {/* <div className='contact-component secondary-lp d-none' >
                <div className="container contact-container">
                    <div className='row'>
                        <div className="col-md-6">
                            <div className='c-formular'>
                                <h1>{t('contact.title')}</h1>
                                <form onSubmit={onSubmit} id='contact-milluu-form' autoComplete="off">
                                    <input type="text" name="name" id="fname"
                                        placeholder={t('contact.name')} required onChange={handleInputChange} />

                                    <input type="email" name="email" id="email_address"
                                        placeholder={t('contact.email')} required onChange={handleInputChange} />

                                    <textarea name="message" id="message"
                                        placeholder={t('contact.message')} required onChange={handleInputChange}></textarea>

                                    <div className='w-100 d-flex justify-content-end mt-3'>
                                        <button type='submit' className='ml-auto btn btn-secondary'>{t('contact.send')}</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                        <div className="col-md-6 c-info order-first order-md-2">
                            <h4>{t('contact.subtitle')}</h4>
                            <ul className="info-list p-1">
                                <li>
                                    <a href={'mailto:'+t('contact.milluu_email')}>
                                        <div className='d-flex align-items-center'>
                                            <div className='contact-icon'>
                                                <svg xmlns="http://www.w3.org/2000/svg" height="16"  width="16" fill='currentColor' viewBox="0 96 960 960"><path d="M180.309 843.999q-26.308 0-45.308-19t-19-45.308V372.309q0-26.308 19-45.308t45.308-19h599.382q27.308 0 45.808 19t18.5 45.308v407.382q0 26.308-18.5 45.308t-45.808 19H180.309ZM480 606.306l312-183.615L785.846 360 480 539 174.154 360 168 422.691l312 183.615Z"/></svg>
                                            </div>
                                            <span>
                                                {t('contact.milluu_email')}
                                            </span>
                                        </div>
                                    </a>
                                </li>

                                <li>
                                    <a href={'tel:'+t('callToActionCall.phone')}>
                                        <div className='d-flex align-items-center'>
                                                <div className='contact-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 96 960 960" width="16" fill='currentColor'>
                                                        <path d="M742.229 888.691q-110.691-10-211.614-59.385-100.922-49.384-178.806-127.769-77.885-78.384-127.961-179.114-50.077-100.73-59.077-211.422-2-21 12.5-36.5t35.5-15.5H317.23q18.539 0 31.616 10.577 13.076 10.577 17.846 28.731L387.615 383q2.384 12.769-1 25.346-3.385 12.577-13.154 21.577l-92.385 89.923q47.385 84.462 110.693 147.385 63.308 62.923 146.232 107.231l94.384-92.923Q643.154 670.77 654 668.655q10.846-2.116 21.846.269l80.075 17.615q18.154 4.385 28.731 18.154 10.577 13.769 10.577 32.308v103.69q0 27-19 38t-34 10Z"/>
                                                    </svg>
                                                </div>
                                                <span>
                                                    {t('callToActionCall.phone')}
                                                </span>
                                            </div>
                                    </a>
                                </li>

                                <div>
                                    {
                                        result && result.done && !result.success &&
                                        <div className="text-danger">{getTextResult()}</div>
                                    }
                                    {
                                        result && result.done && result.success &&
                                        <div className="text-success">{t(getTextResult())}</div>
                                    }
                                </div>
                            </ul>

                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default ContactComponent;