import React from 'react';
import {Link} from "react-router-dom";

import './MainButton.scss';

const MainButton = (props) => {
    return (
        <>
            {props.to ?
                <Link className={`main-button ${props.className} ${props.color} ${props.shadow ? 'with-shadow' : ''} ${props.whiteButton ? 'white-button' : ''}`} to={props.to}>
                    {props.title}
                </Link>
                :
                <a onClick={props.onClick} href={props.href} className={`main-button ${props.color} ${props.shadow ? 'with-shadow' : ''} ${props.whiteButton ? 'white-button' : ''}`}>
                    {props.title}
                </a>
            }
        </>
    );
};

export default MainButton;