import React, { useState, useRef } from 'react';
import {useTranslation, Trans} from "react-i18next";

import Container from "react-bootstrap/Container";

import Features from "../../components/features/Features";
import FeaturesSection from '../../components/features-section/FeaturesSection';
import WhatIsMilluu from "../../components/what-is-milluu/WhatIsMilluu";
import News from "../../components/news/News";
import TenantsServices from '../../components/tenants-services/TenantsServices';

import ico_expand_more from './img/expand-more.svg';
import ico_android from './img/android.svg';
import ico_ios from './img/ios.svg';
import ico_main from './img/tenants_main_3.jpg';
import ico_main_ro from './img/tenants_main_3_ro.webp';

import Steps2Container from "../../components/steps2/Steps2Container";

import '../../resources/styles/secondary-lp.scss';
import './Tenants.scss';

import { AppModelService } from '../../AppModel';

const TenantsComponent = (props) => {

    const {t} = useTranslation();

    const [buttonStyle, setButtonStyle] = useState('white-button');

    function actionDropdownClicked(e) {
        setButtonStyle(buttonStyle === 'white-button' ? 'black-button' : 'white-button');
    }

    function stopPropagation(e) {
        e.stopPropagation();
    }

    return (
        <React.Fragment>
            <div className="tenantsV2-component">

                <Container className="container-page-aligned wrapper-section-hero">
                    <div className='row g-0'>
                        <div className='col-12 col-md-6'>
                            <div className="wrapper-hero-description">
                                <div>
                                    <h2 className="tenant-h1"><Trans i18nKey={`bannerTenantsH1.title1`} /></h2>
                                    <h2 className="tenant-h2"><Trans i18nKey={`bannerTenantsH1.title2`} /></h2>
                                    <h2 className="tenant-h3"><Trans i18nKey={`bannerTenantsH1.title3`} /></h2>

                                    <div className="action-container mb-5">
                                        <div id="actionButton" className={'main-button ' + buttonStyle + ' milluu-w-md-auto download-button-container'} onClick={actionDropdownClicked}>
                                            <div onClick={actionDropdownClicked} className='download-button'>
                                                {t(`bannerTenants.button.title`)}
                                                <img className='ms-1' src={ico_expand_more} alt='' />
                                            </div>

                                            <div className={'download-container mt-2' + (buttonStyle === 'white-button'? ' d-none ': '')} onClick={stopPropagation}>
                                                <a href={AppModelService.getGooleAppLink()}>
                                                    <div className='d-flex flex-row align-items-center'>
                                                        <img src={ico_android} alt='' />
                                                        <div className='ms-2'>{t('bannerTenantsH1.downloadAndroid')}</div>
                                                    </div>
                                                </a>
                                                <a href={AppModelService.getIosAppLink()}>
                                                    <div className='d-flex flex-row align-items-center mt-2'>
                                                        <img src={ico_ios} alt='' />
                                                        <div className='ms-2'>{t('bannerTenantsH1.downloadIOS')}</div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className='wrapper-hero-img'>

                                {(AppModelService.getCountry() === 'pl') &&
                                <img className='ms-1' src={ico_main} alt='' />
                                }

                                {(AppModelService.getCountry() === 'ro') &&
                                <img className='ms-1' src={ico_main_ro} alt='' />
                                }

                            </div>
                        </div>
                    </div>
                </Container>

                <FeaturesSection
                    title={t(`featuresTenants.title`)}
                    subtitle={t(`featuresTenants.subtitle`)}
                    features={t(`featuresTenants.features`, { returnObjects: true })}>
                </FeaturesSection>

                <Steps2Container
                        steps={t(`stepsTenants.steps`, { returnObjects: true })}
                        title={<Trans i18nKey={`stepsTenants.title`} />}
                        colXL='col-xl-4'
                        subtitle={<Trans i18nKey={`stepsTenants.subtitle`} />}
                />

                <TenantsServices
                    showModal={props.showModal}
                    handleShowModal={props.handleShowModal}
                    handleCloseModal={props.handleCloseModal}
                    showModalFee={props.showModalFee}
                    handleShowModalFee={props.handleShowModalFee}
                    handleCloseModalFee={props.handleCloseModalFee}
                    tableActiveColumn={props.tableActiveColumn}
                    handleSetTableActiveColumn={props.handleSetTableActiveColumn} />

                <WhatIsMilluu />

                <News className="wrapper-tenants-news" />
            </div>




            {/* <div className="tenants-component secondary-lp">
                <Hero className="custom-hero" background={bgHero} gradient={gradientHero}>
                    <div className="wrapper-hero-description d-flex flex-column align-items-center">
                        <img src={illustrationChirias} alt=""/>
                        <h1><Trans i18nKey={`bannerTenants.title`}/></h1>
                    </div>
                    <MainButton title={t(`bannerTenants.button.title`)} href={t(`bannerTenants.button.link`)} shadow />
                </Hero>

                <div className="wrapper-section-under-hero">
                    <Features features={t(`featuresTenants.features`, { returnObjects: true })}>
                        <h2 className="section-title section-features-title border-color-green">{t(`featuresTenants.title`)}</h2>
                        <p className="section-title"><strong>{t(`featuresTenants.subtitle`)} <br/>
                            <span className="green-text">{t(`featuresTenants.coloredText`)}</span></strong></p>
                    </Features>
                </div>

                <StepsContainer steps={t(`stepsTenants.steps`, { returnObjects: true })} title={<Trans i18nKey={`stepsTenants.title`}/>} color="green"/>

                <div className="section-costs">
                    <Container>
                        <h2 className="section-title"><Trans i18nKey={`servicesTenants.title`}/></h2>
                        <div className="table-services">
                            <div className="box-wrapper">
                                <div className="d-flex align-items-center title-table">
                                    <img src={milluuCarefree} alt=""/>
                                    <h2 className="plan-title section-title orange-text"><strong>Milluu Zen</strong></h2>
                                </div>
                                <div className="options">
                                    {(t(`servicesTenants.options`, { returnObjects: true })).map((option, optIndex) => {
                                        return (
                                            <div key={optIndex} className="option d-flex align-items-center justify-content-md-between">
                                                <Modal show={props.showModal[optIndex].modalInfo.open} onHide={() => props.handleCloseModal(optIndex)}
                                                    className="custom-modal step-modal" centered scrollable>
                                                    <Modal.Header closeButton className="header-modal">
                                                        <h2 className="section-title small-header"><strong>{option.title} </strong></h2>
                                                    </Modal.Header>
                                                    <p dangerouslySetInnerHTML={{ __html: option.modalInfo.description }} />
                                                </Modal>

                                                <p>{option.title} <button
                                                    className="unstyled-button info-btn"
                                                    onClick={() => props.handleShowModal(optIndex)}><img src={iconInfo} alt=""/>
                                                </button></p>
                                                <img src={iconCheck} alt=""/>
                                            </div>
                                        )
                                    })}
                                </div>
                                {(t(`servicesTenants.fees`, { returnObjects: true })).map((fee, index) => {
                                    return (
                                        <div key={index} className="info-wrapper">
                                            <h3><Trans i18nKey={fee.title}/></h3>
                                            <p>{fee.description}</p>
                                            <p className="obs">{fee.observation}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </Container>
                </div>

                { (AppModelService.getCountry() === 'ro') &&
                <SliderTestimonials testimonials={t(`reviewsTenants.reviews`, { returnObjects: true })}>
                    <Trans i18nKey={`reviewsTenants.title`}/>
                </SliderTestimonials>
                }

                <div id="app">
                    <WhatIsMilluu/>
                </div>

                <div className='float-container'>
                    <ContactUsComponent/>
                </div>
            </div> */}
        </React.Fragment>
    );
};

export default TenantsComponent;